<script setup lang="ts">
import { resolveNodeId } from '@manager'
import ManagerGroup from './Group.vue'
import type { IManagerGroupEmits, IManagerGroupProps } from './types'

const props = withDefaults(defineProps<IManagerGroupProps>(), {
  parentValue: () => ({}),
  showMenu: false,
})
const emit = defineEmits<IManagerGroupEmits>()

const parentValue = useVModel(props, 'parentValue', emit)
</script>

<template>
  <!-- Sub Group -->
  <ManagerGroup v-bind="props" v-model:parent-value="parentValue">
    <template #header>
      <h2 class="flex w-full items-center text-gray-200">
        <Icon
          :key="'icon' + node.name"
          :name="'review/' + node.name"
          class="mr-2 text-xl text-gray-600"
          filled
        />
        {{ node.label }}
      </h2>
    </template>
    <template #default="{ state: [innerValue] }">
      <ManagerNode
        v-for="childNode in node.nodes"
        :key="resolveNodeId(childNode)"
        v-model:parent-value="innerValue.value"
        :node="childNode"
      />
    </template>
  </ManagerGroup>
</template>
